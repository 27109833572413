/**
 * NOTE: In order to avoid circular dependencies, if you add a function to this module and it needs to print something,
 * you must either a) use `console.log` rather than the logger, or b) put your function elsewhere.
 */
import { isNodeEnv } from './node';
var fallbackGlobalObject = {};
/**
 * Safely get global scope object
 *
 * @returns Global scope object
 */
export function getGlobalObject() {
  return isNodeEnv() ? global : typeof window !== 'undefined' // eslint-disable-line no-restricted-globals
  ? window // eslint-disable-line no-restricted-globals
  : typeof self !== 'undefined' ? self : fallbackGlobalObject;
}
/**
 * Returns a global singleton contained in the global `__SENTRY__` object.
 *
 * If the singleton doesn't already exist in `__SENTRY__`, it will be created using the given factory
 * function and added to the `__SENTRY__` object.
 *
 * @param name name of the global singleton on __SENTRY__
 * @param creator creator Factory function to create the singleton if it doesn't already exist on `__SENTRY__`
 * @param obj (Optional) The global object on which to look for `__SENTRY__`, if not `getGlobalObject`'s return value
 * @returns the singleton
 */
export function getGlobalSingleton(name, creator, obj) {
  var global = obj || getGlobalObject();
  var __SENTRY__ = global.__SENTRY__ = global.__SENTRY__ || {};
  var singleton = __SENTRY__[name] || (__SENTRY__[name] = creator());
  return singleton;
}
