import { __assign, __values } from "tslib";
var STACKTRACE_LIMIT = 50;
/**
 * Creates a stack parser with the supplied line parsers
 *
 * StackFrames are returned in the correct order for Sentry Exception
 * frames and with Sentry SDK internal frames removed from the top and bottom
 *
 */
export function createStackParser() {
  var parsers = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    parsers[_i] = arguments[_i];
  }
  var sortedParsers = parsers.sort(function (a, b) {
    return a[0] - b[0];
  }).map(function (p) {
    return p[1];
  });
  return function (stack, skipFirst) {
    var e_1, _a, e_2, _b;
    if (skipFirst === void 0) {
      skipFirst = 0;
    }
    var frames = [];
    try {
      for (var _c = __values(stack.split('\n').slice(skipFirst)), _d = _c.next(); !_d.done; _d = _c.next()) {
        var line = _d.value;
        try {
          for (var sortedParsers_1 = (e_2 = void 0, __values(sortedParsers)), sortedParsers_1_1 = sortedParsers_1.next(); !sortedParsers_1_1.done; sortedParsers_1_1 = sortedParsers_1.next()) {
            var parser = sortedParsers_1_1.value;
            var frame = parser(line);
            if (frame) {
              frames.push(frame);
              break;
            }
          }
        } catch (e_2_1) {
          e_2 = {
            error: e_2_1
          };
        } finally {
          try {
            if (sortedParsers_1_1 && !sortedParsers_1_1.done && (_b = sortedParsers_1.return)) _b.call(sortedParsers_1);
          } finally {
            if (e_2) throw e_2.error;
          }
        }
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
    return stripSentryFramesAndReverse(frames);
  };
}
/**
 * @hidden
 */
export function stripSentryFramesAndReverse(stack) {
  if (!stack.length) {
    return [];
  }
  var localStack = stack;
  var firstFrameFunction = localStack[0].function || '';
  var lastFrameFunction = localStack[localStack.length - 1].function || '';
  // If stack starts with one of our API calls, remove it (starts, meaning it's the top of the stack - aka last call)
  if (firstFrameFunction.indexOf('captureMessage') !== -1 || firstFrameFunction.indexOf('captureException') !== -1) {
    localStack = localStack.slice(1);
  }
  // If stack ends with one of our internal API calls, remove it (ends, meaning it's the bottom of the stack - aka top-most call)
  if (lastFrameFunction.indexOf('sentryWrapped') !== -1) {
    localStack = localStack.slice(0, -1);
  }
  // The frame where the crash happened, should be the last entry in the array
  return localStack.slice(0, STACKTRACE_LIMIT).map(function (frame) {
    return __assign(__assign({}, frame), {
      filename: frame.filename || localStack[0].filename,
      function: frame.function || '?'
    });
  }).reverse();
}
var defaultFunctionName = '<anonymous>';
/**
 * Safely extract function name from itself
 */
export function getFunctionName(fn) {
  try {
    if (!fn || typeof fn !== 'function') {
      return defaultFunctionName;
    }
    return fn.name || defaultFunctionName;
  } catch (e) {
    // Just accessing custom props in some Selenium environments
    // can cause a "Permission denied" exception (see raven-js#495).
    return defaultFunctionName;
  }
}
