import { Severity } from '@sentry/types';
import { SeverityLevels } from './enums';
function isSupportedSeverity(level) {
  return SeverityLevels.indexOf(level) !== -1;
}
/**
 * Converts a string-based level into a {@link Severity}.
 *
 * @param level string representation of Severity
 * @returns Severity
 */
export function severityFromString(level) {
  if (level === 'warn') return Severity.Warning;
  if (isSupportedSeverity(level)) {
    return level;
  }
  return Severity.Log;
}
