<div class="sidenav mat-bg-card w-64 min-h-full flex flex-col">
  <mat-nav-list class="flex-1">
    <a
      *ngFor="let item of menuItems"
      mat-list-item
      [routerLink]="item.link"
      routerLinkActive="active"
      (click)="buttonClick.emit()"
    >
      <mat-icon matListItemIcon [svgIcon]="item.icon" />
      <p matListItemTitle>{{ item.title }}</p>
    </a>
  </mat-nav-list>

  <mat-divider />

  <div class="p-4">
    <div class="mat-body select-none mb-2">
      <div>
        <a
          class="text-inherit leading-5 no-underline"
          href="https://poiscript.notion.site/HoloStats-Changelog-8363eab6c41f419d93bf104620c55bd6"
          target="_blank"
        >
          Changelog
        </a>

        <a
          class="text-inherit leading-5 no-underline ml-1"
          href="https://www.buymeacoffee.com/holostats"
          target="_blank"
        >
          Donate
        </a>
      </div>

      <div>
        <a
          class="text-inherit leading-5 no-underline"
          href="https://angular.io/"
          target="_blank"
        >
          Angular: v{{ angularVer }}
        </a>
      </div>

      <div>
        <a
          class="text-inherit leading-5 no-underline"
          href="https://github.com/PoiScript/HoloStats/commit/{{ commitId }}"
          target="_blank"
        >
          Commit: {{ commitId }}
        </a>

        <hls-update-indicator />
      </div>
    </div>

    <div class="mat-small mat-secondary-text">&copy; 2019-2023 PoiScript</div>
  </div>
</div>
