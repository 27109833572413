import { logger } from '@sentry/utils';
import { IS_DEBUG_BUILD } from '../flags';
/** Deduplication filter */
var Dedupe = /** @class */function () {
  function Dedupe() {
    /**
     * @inheritDoc
     */
    this.name = Dedupe.id;
  }
  /**
   * @inheritDoc
   */
  Dedupe.prototype.setupOnce = function (addGlobalEventProcessor, getCurrentHub) {
    addGlobalEventProcessor(function (currentEvent) {
      var self = getCurrentHub().getIntegration(Dedupe);
      if (self) {
        // Juuust in case something goes wrong
        try {
          if (_shouldDropEvent(currentEvent, self._previousEvent)) {
            IS_DEBUG_BUILD && logger.warn('Event dropped due to being a duplicate of previously captured event.');
            return null;
          }
        } catch (_oO) {
          return self._previousEvent = currentEvent;
        }
        return self._previousEvent = currentEvent;
      }
      return currentEvent;
    });
  };
  /**
   * @inheritDoc
   */
  Dedupe.id = 'Dedupe';
  return Dedupe;
}();
export { Dedupe };
/** JSDoc */
function _shouldDropEvent(currentEvent, previousEvent) {
  if (!previousEvent) {
    return false;
  }
  if (_isSameMessageEvent(currentEvent, previousEvent)) {
    return true;
  }
  if (_isSameExceptionEvent(currentEvent, previousEvent)) {
    return true;
  }
  return false;
}
/** JSDoc */
function _isSameMessageEvent(currentEvent, previousEvent) {
  var currentMessage = currentEvent.message;
  var previousMessage = previousEvent.message;
  // If neither event has a message property, they were both exceptions, so bail out
  if (!currentMessage && !previousMessage) {
    return false;
  }
  // If only one event has a stacktrace, but not the other one, they are not the same
  if (currentMessage && !previousMessage || !currentMessage && previousMessage) {
    return false;
  }
  if (currentMessage !== previousMessage) {
    return false;
  }
  if (!_isSameFingerprint(currentEvent, previousEvent)) {
    return false;
  }
  if (!_isSameStacktrace(currentEvent, previousEvent)) {
    return false;
  }
  return true;
}
/** JSDoc */
function _isSameExceptionEvent(currentEvent, previousEvent) {
  var previousException = _getExceptionFromEvent(previousEvent);
  var currentException = _getExceptionFromEvent(currentEvent);
  if (!previousException || !currentException) {
    return false;
  }
  if (previousException.type !== currentException.type || previousException.value !== currentException.value) {
    return false;
  }
  if (!_isSameFingerprint(currentEvent, previousEvent)) {
    return false;
  }
  if (!_isSameStacktrace(currentEvent, previousEvent)) {
    return false;
  }
  return true;
}
/** JSDoc */
function _isSameStacktrace(currentEvent, previousEvent) {
  var currentFrames = _getFramesFromEvent(currentEvent);
  var previousFrames = _getFramesFromEvent(previousEvent);
  // If neither event has a stacktrace, they are assumed to be the same
  if (!currentFrames && !previousFrames) {
    return true;
  }
  // If only one event has a stacktrace, but not the other one, they are not the same
  if (currentFrames && !previousFrames || !currentFrames && previousFrames) {
    return false;
  }
  currentFrames = currentFrames;
  previousFrames = previousFrames;
  // If number of frames differ, they are not the same
  if (previousFrames.length !== currentFrames.length) {
    return false;
  }
  // Otherwise, compare the two
  for (var i = 0; i < previousFrames.length; i++) {
    var frameA = previousFrames[i];
    var frameB = currentFrames[i];
    if (frameA.filename !== frameB.filename || frameA.lineno !== frameB.lineno || frameA.colno !== frameB.colno || frameA.function !== frameB.function) {
      return false;
    }
  }
  return true;
}
/** JSDoc */
function _isSameFingerprint(currentEvent, previousEvent) {
  var currentFingerprint = currentEvent.fingerprint;
  var previousFingerprint = previousEvent.fingerprint;
  // If neither event has a fingerprint, they are assumed to be the same
  if (!currentFingerprint && !previousFingerprint) {
    return true;
  }
  // If only one event has a fingerprint, but not the other one, they are not the same
  if (currentFingerprint && !previousFingerprint || !currentFingerprint && previousFingerprint) {
    return false;
  }
  currentFingerprint = currentFingerprint;
  previousFingerprint = previousFingerprint;
  // Otherwise, compare the two
  try {
    return !!(currentFingerprint.join('') === previousFingerprint.join(''));
  } catch (_oO) {
    return false;
  }
}
/** JSDoc */
function _getExceptionFromEvent(event) {
  return event.exception && event.exception.values && event.exception.values[0];
}
/** JSDoc */
function _getFramesFromEvent(event) {
  var exception = event.exception;
  if (exception) {
    try {
      // @ts-ignore Object could be undefined
      return exception.values[0].stacktrace.frames;
    } catch (_oO) {
      return undefined;
    }
  } else if (event.stacktrace) {
    return event.stacktrace.frames;
  }
  return undefined;
}
