import { __assign, __extends } from "tslib";
import { BaseClient, SDK_VERSION } from '@sentry/core';
import { getGlobalObject, logger } from '@sentry/utils';
import { BrowserBackend } from './backend';
import { IS_DEBUG_BUILD } from './flags';
import { injectReportDialog } from './helpers';
import { Breadcrumbs } from './integrations';
/**
 * The Sentry Browser SDK Client.
 *
 * @see BrowserOptions for documentation on configuration options.
 * @see SentryClient for usage documentation.
 */
var BrowserClient = /** @class */function (_super) {
  __extends(BrowserClient, _super);
  /**
   * Creates a new Browser SDK instance.
   *
   * @param options Configuration options for this SDK.
   */
  function BrowserClient(options) {
    if (options === void 0) {
      options = {};
    }
    var _this = this;
    options._metadata = options._metadata || {};
    options._metadata.sdk = options._metadata.sdk || {
      name: 'sentry.javascript.browser',
      packages: [{
        name: 'npm:@sentry/browser',
        version: SDK_VERSION
      }],
      version: SDK_VERSION
    };
    _this = _super.call(this, BrowserBackend, options) || this;
    return _this;
  }
  /**
   * Show a report dialog to the user to send feedback to a specific event.
   *
   * @param options Set individual options for the dialog
   */
  BrowserClient.prototype.showReportDialog = function (options) {
    if (options === void 0) {
      options = {};
    }
    // doesn't work without a document (React Native)
    var document = getGlobalObject().document;
    if (!document) {
      return;
    }
    if (!this._isEnabled()) {
      IS_DEBUG_BUILD && logger.error('Trying to call showReportDialog with Sentry Client disabled');
      return;
    }
    injectReportDialog(__assign(__assign({}, options), {
      dsn: options.dsn || this.getDsn()
    }));
  };
  /**
   * @inheritDoc
   */
  BrowserClient.prototype._prepareEvent = function (event, scope, hint) {
    event.platform = event.platform || 'javascript';
    return _super.prototype._prepareEvent.call(this, event, scope, hint);
  };
  /**
   * @inheritDoc
   */
  BrowserClient.prototype._sendEvent = function (event) {
    var integration = this.getIntegration(Breadcrumbs);
    if (integration) {
      integration.addSentryBreadcrumb(event);
    }
    _super.prototype._sendEvent.call(this, event);
  };
  return BrowserClient;
}(BaseClient);
export { BrowserClient };
