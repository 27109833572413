<hls-header (menuClick)="drawer.toggle()" />

<mat-sidenav-container>
  <mat-sidenav
    #drawer
    [mode]="sidenavMode"
    [opened]="sidenavShouldOpen"
    [fixedInViewport]="true"
    [fixedTopGap]="56"
  >
    <hls-sidenav (buttonClick)="sidenavShouldOpen ? null : drawer.toggle()" />
  </mat-sidenav>
  <mat-sidenav-content class="pt-16 min-h-[calc(100vh-4rem)]">
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
