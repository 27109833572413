import { __extends } from "tslib";
import { SentryError, supportsReferrerPolicy, SyncPromise } from '@sentry/utils';
import { BaseTransport } from './base';
import { getNativeFetchImplementation } from './utils';
/** `fetch` based transport */
var FetchTransport = /** @class */function (_super) {
  __extends(FetchTransport, _super);
  function FetchTransport(options, fetchImpl) {
    if (fetchImpl === void 0) {
      fetchImpl = getNativeFetchImplementation();
    }
    var _this = _super.call(this, options) || this;
    _this._fetch = fetchImpl;
    return _this;
  }
  /**
   * @param sentryRequest Prepared SentryRequest to be delivered
   * @param originalPayload Original payload used to create SentryRequest
   */
  FetchTransport.prototype._sendRequest = function (sentryRequest, originalPayload) {
    var _this = this;
    // eslint-disable-next-line deprecation/deprecation
    if (this._isRateLimited(sentryRequest.type)) {
      this.recordLostEvent('ratelimit_backoff', sentryRequest.type);
      return Promise.reject({
        event: originalPayload,
        type: sentryRequest.type,
        // eslint-disable-next-line deprecation/deprecation
        reason: "Transport for " + sentryRequest.type + " requests locked till " + this._disabledUntil(sentryRequest.type) + " due to too many requests.",
        status: 429
      });
    }
    var options = {
      body: sentryRequest.body,
      method: 'POST',
      // Despite all stars in the sky saying that Edge supports old draft syntax, aka 'never', 'always', 'origin' and 'default'
      // (see https://caniuse.com/#feat=referrer-policy),
      // it doesn't. And it throws an exception instead of ignoring this parameter...
      // REF: https://github.com/getsentry/raven-js/issues/1233
      referrerPolicy: supportsReferrerPolicy() ? 'origin' : ''
    };
    if (this.options.fetchParameters !== undefined) {
      Object.assign(options, this.options.fetchParameters);
    }
    if (this.options.headers !== undefined) {
      options.headers = this.options.headers;
    }
    return this._buffer.add(function () {
      return new SyncPromise(function (resolve, reject) {
        void _this._fetch(sentryRequest.url, options).then(function (response) {
          var headers = {
            'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
            'retry-after': response.headers.get('Retry-After')
          };
          _this._handleResponse({
            requestType: sentryRequest.type,
            response: response,
            headers: headers,
            resolve: resolve,
            reject: reject
          });
        }).catch(reject);
      });
    }).then(undefined, function (reason) {
      // It's either buffer rejection or any other xhr/fetch error, which are treated as NetworkError.
      if (reason instanceof SentryError) {
        _this.recordLostEvent('queue_overflow', sentryRequest.type);
      } else {
        _this.recordLostEvent('network_error', sentryRequest.type);
      }
      throw reason;
    });
  };
  return FetchTransport;
}(BaseTransport);
export { FetchTransport };
