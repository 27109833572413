import { getOriginalFunction } from '@sentry/utils';
var originalFunctionToString;
/** Patch toString calls to return proper name for wrapped functions */
var FunctionToString = /** @class */function () {
  function FunctionToString() {
    /**
     * @inheritDoc
     */
    this.name = FunctionToString.id;
  }
  /**
   * @inheritDoc
   */
  FunctionToString.prototype.setupOnce = function () {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    originalFunctionToString = Function.prototype.toString;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Function.prototype.toString = function () {
      var args = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }
      var context = getOriginalFunction(this) || this;
      return originalFunctionToString.apply(context, args);
    };
  };
  /**
   * @inheritDoc
   */
  FunctionToString.id = 'FunctionToString';
  return FunctionToString;
}();
export { FunctionToString };
