/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isThenable } from './is';
/**
 * Creates a resolved sync promise.
 *
 * @param value the value to resolve the promise with
 * @returns the resolved sync promise
 */
export function resolvedSyncPromise(value) {
  return new SyncPromise(function (resolve) {
    resolve(value);
  });
}
/**
 * Creates a rejected sync promise.
 *
 * @param value the value to reject the promise with
 * @returns the rejected sync promise
 */
export function rejectedSyncPromise(reason) {
  return new SyncPromise(function (_, reject) {
    reject(reason);
  });
}
/**
 * Thenable class that behaves like a Promise and follows it's interface
 * but is not async internally
 */
var SyncPromise = /** @class */function () {
  function SyncPromise(executor) {
    var _this = this;
    this._state = 0 /* PENDING */;
    this._handlers = [];
    /** JSDoc */
    this._resolve = function (value) {
      _this._setResult(1 /* RESOLVED */, value);
    };
    /** JSDoc */
    this._reject = function (reason) {
      _this._setResult(2 /* REJECTED */, reason);
    };
    /** JSDoc */
    this._setResult = function (state, value) {
      if (_this._state !== 0 /* PENDING */) {
        return;
      }
      if (isThenable(value)) {
        void value.then(_this._resolve, _this._reject);
        return;
      }
      _this._state = state;
      _this._value = value;
      _this._executeHandlers();
    };
    /** JSDoc */
    this._executeHandlers = function () {
      if (_this._state === 0 /* PENDING */) {
        return;
      }
      var cachedHandlers = _this._handlers.slice();
      _this._handlers = [];
      cachedHandlers.forEach(function (handler) {
        if (handler[0]) {
          return;
        }
        if (_this._state === 1 /* RESOLVED */) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          handler[1](_this._value);
        }
        if (_this._state === 2 /* REJECTED */) {
          handler[2](_this._value);
        }
        handler[0] = true;
      });
    };
    try {
      executor(this._resolve, this._reject);
    } catch (e) {
      this._reject(e);
    }
  }
  /** JSDoc */
  SyncPromise.prototype.then = function (onfulfilled, onrejected) {
    var _this = this;
    return new SyncPromise(function (resolve, reject) {
      _this._handlers.push([false, function (result) {
        if (!onfulfilled) {
          // TODO: ¯\_(ツ)_/¯
          // TODO: FIXME
          resolve(result);
        } else {
          try {
            resolve(onfulfilled(result));
          } catch (e) {
            reject(e);
          }
        }
      }, function (reason) {
        if (!onrejected) {
          reject(reason);
        } else {
          try {
            resolve(onrejected(reason));
          } catch (e) {
            reject(e);
          }
        }
      }]);
      _this._executeHandlers();
    });
  };
  /** JSDoc */
  SyncPromise.prototype.catch = function (onrejected) {
    return this.then(function (val) {
      return val;
    }, onrejected);
  };
  /** JSDoc */
  SyncPromise.prototype.finally = function (onfinally) {
    var _this = this;
    return new SyncPromise(function (resolve, reject) {
      var val;
      var isRejected;
      return _this.then(function (value) {
        isRejected = false;
        val = value;
        if (onfinally) {
          onfinally();
        }
      }, function (reason) {
        isRejected = true;
        val = reason;
        if (onfinally) {
          onfinally();
        }
      }).then(function () {
        if (isRejected) {
          reject(val);
          return;
        }
        resolve(val);
      });
    });
  };
  return SyncPromise;
}();
export { SyncPromise };
