import { __extends } from "tslib";
import { SentryError, SyncPromise } from '@sentry/utils';
import { BaseTransport } from './base';
/** `XHR` based transport */
var XHRTransport = /** @class */function (_super) {
  __extends(XHRTransport, _super);
  function XHRTransport() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * @param sentryRequest Prepared SentryRequest to be delivered
   * @param originalPayload Original payload used to create SentryRequest
   */
  XHRTransport.prototype._sendRequest = function (sentryRequest, originalPayload) {
    var _this = this;
    // eslint-disable-next-line deprecation/deprecation
    if (this._isRateLimited(sentryRequest.type)) {
      this.recordLostEvent('ratelimit_backoff', sentryRequest.type);
      return Promise.reject({
        event: originalPayload,
        type: sentryRequest.type,
        // eslint-disable-next-line deprecation/deprecation
        reason: "Transport for " + sentryRequest.type + " requests locked till " + this._disabledUntil(sentryRequest.type) + " due to too many requests.",
        status: 429
      });
    }
    return this._buffer.add(function () {
      return new SyncPromise(function (resolve, reject) {
        var request = new XMLHttpRequest();
        request.onreadystatechange = function () {
          if (request.readyState === 4) {
            var headers = {
              'x-sentry-rate-limits': request.getResponseHeader('X-Sentry-Rate-Limits'),
              'retry-after': request.getResponseHeader('Retry-After')
            };
            _this._handleResponse({
              requestType: sentryRequest.type,
              response: request,
              headers: headers,
              resolve: resolve,
              reject: reject
            });
          }
        };
        request.open('POST', sentryRequest.url);
        for (var header in _this.options.headers) {
          if (Object.prototype.hasOwnProperty.call(_this.options.headers, header)) {
            request.setRequestHeader(header, _this.options.headers[header]);
          }
        }
        request.send(sentryRequest.body);
      });
    }).then(undefined, function (reason) {
      // It's either buffer rejection or any other xhr/fetch error, which are treated as NetworkError.
      if (reason instanceof SentryError) {
        _this.recordLostEvent('queue_overflow', sentryRequest.type);
      } else {
        _this.recordLostEvent('network_error', sentryRequest.type);
      }
      throw reason;
    });
  };
  return XHRTransport;
}(BaseTransport);
export { XHRTransport };
