import { dropUndefinedKeys, timestampInSeconds, uuid4 } from '@sentry/utils';
/**
 * @inheritdoc
 */
var Session = /** @class */function () {
  function Session(context) {
    this.errors = 0;
    this.sid = uuid4();
    this.duration = 0;
    this.status = 'ok';
    this.init = true;
    this.ignoreDuration = false;
    // Both timestamp and started are in seconds since the UNIX epoch.
    var startingTime = timestampInSeconds();
    this.timestamp = startingTime;
    this.started = startingTime;
    if (context) {
      this.update(context);
    }
  }
  /** JSDoc */
  // eslint-disable-next-line complexity
  Session.prototype.update = function (context) {
    if (context === void 0) {
      context = {};
    }
    if (context.user) {
      if (!this.ipAddress && context.user.ip_address) {
        this.ipAddress = context.user.ip_address;
      }
      if (!this.did && !context.did) {
        this.did = context.user.id || context.user.email || context.user.username;
      }
    }
    this.timestamp = context.timestamp || timestampInSeconds();
    if (context.ignoreDuration) {
      this.ignoreDuration = context.ignoreDuration;
    }
    if (context.sid) {
      // Good enough uuid validation. — Kamil
      this.sid = context.sid.length === 32 ? context.sid : uuid4();
    }
    if (context.init !== undefined) {
      this.init = context.init;
    }
    if (!this.did && context.did) {
      this.did = "" + context.did;
    }
    if (typeof context.started === 'number') {
      this.started = context.started;
    }
    if (this.ignoreDuration) {
      this.duration = undefined;
    } else if (typeof context.duration === 'number') {
      this.duration = context.duration;
    } else {
      var duration = this.timestamp - this.started;
      this.duration = duration >= 0 ? duration : 0;
    }
    if (context.release) {
      this.release = context.release;
    }
    if (context.environment) {
      this.environment = context.environment;
    }
    if (!this.ipAddress && context.ipAddress) {
      this.ipAddress = context.ipAddress;
    }
    if (!this.userAgent && context.userAgent) {
      this.userAgent = context.userAgent;
    }
    if (typeof context.errors === 'number') {
      this.errors = context.errors;
    }
    if (context.status) {
      this.status = context.status;
    }
  };
  /** JSDoc */
  Session.prototype.close = function (status) {
    if (status) {
      this.update({
        status: status
      });
    } else if (this.status === 'ok') {
      this.update({
        status: 'exited'
      });
    } else {
      this.update();
    }
  };
  /** JSDoc */
  Session.prototype.toJSON = function () {
    return dropUndefinedKeys({
      sid: "" + this.sid,
      init: this.init,
      // Make sure that sec is converted to ms for date constructor
      started: new Date(this.started * 1000).toISOString(),
      timestamp: new Date(this.timestamp * 1000).toISOString(),
      status: this.status,
      errors: this.errors,
      did: typeof this.did === 'number' || typeof this.did === 'string' ? "" + this.did : undefined,
      duration: this.duration,
      attrs: {
        release: this.release,
        environment: this.environment,
        ip_address: this.ipAddress,
        user_agent: this.userAgent
      }
    });
  };
  return Session;
}();
export { Session };
