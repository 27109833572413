import { __read, __spread } from "tslib";
import { addGlobalEventProcessor, getCurrentHub } from '@sentry/hub';
import { addNonEnumerableProperty, logger } from '@sentry/utils';
import { IS_DEBUG_BUILD } from './flags';
export var installedIntegrations = [];
/**
 * @private
 */
function filterDuplicates(integrations) {
  return integrations.reduce(function (acc, integrations) {
    if (acc.every(function (accIntegration) {
      return integrations.name !== accIntegration.name;
    })) {
      acc.push(integrations);
    }
    return acc;
  }, []);
}
/** Gets integration to install */
export function getIntegrationsToSetup(options) {
  var defaultIntegrations = options.defaultIntegrations && __spread(options.defaultIntegrations) || [];
  var userIntegrations = options.integrations;
  var integrations = __spread(filterDuplicates(defaultIntegrations));
  if (Array.isArray(userIntegrations)) {
    // Filter out integrations that are also included in user options
    integrations = __spread(integrations.filter(function (integrations) {
      return userIntegrations.every(function (userIntegration) {
        return userIntegration.name !== integrations.name;
      });
    }), filterDuplicates(userIntegrations));
  } else if (typeof userIntegrations === 'function') {
    integrations = userIntegrations(integrations);
    integrations = Array.isArray(integrations) ? integrations : [integrations];
  }
  // Make sure that if present, `Debug` integration will always run last
  var integrationsNames = integrations.map(function (i) {
    return i.name;
  });
  var alwaysLastToRun = 'Debug';
  if (integrationsNames.indexOf(alwaysLastToRun) !== -1) {
    integrations.push.apply(integrations, __spread(integrations.splice(integrationsNames.indexOf(alwaysLastToRun), 1)));
  }
  return integrations;
}
/** Setup given integration */
export function setupIntegration(integration) {
  if (installedIntegrations.indexOf(integration.name) !== -1) {
    return;
  }
  integration.setupOnce(addGlobalEventProcessor, getCurrentHub);
  installedIntegrations.push(integration.name);
  IS_DEBUG_BUILD && logger.log("Integration installed: " + integration.name);
}
/**
 * Given a list of integration instances this installs them all. When `withDefaults` is set to `true` then all default
 * integrations are added unless they were already provided before.
 * @param integrations array of integration instances
 * @param withDefault should enable default integrations
 */
export function setupIntegrations(options) {
  var integrations = {};
  getIntegrationsToSetup(options).forEach(function (integration) {
    integrations[integration.name] = integration;
    setupIntegration(integration);
  });
  // set the `initialized` flag so we don't run through the process again unecessarily; use `Object.defineProperty`
  // because by default it creates a property which is nonenumerable, which we want since `initialized` shouldn't be
  // considered a member of the index the way the actual integrations are
  addNonEnumerableProperty(integrations, 'initialized', true);
  return integrations;
}
