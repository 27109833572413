import { __assign } from "tslib";
import { captureException, getReportDialogEndpoint, withScope } from '@sentry/core';
import { addExceptionMechanism, addExceptionTypeValue, addNonEnumerableProperty, getGlobalObject, getOriginalFunction, logger, markFunctionWrapped } from '@sentry/utils';
import { IS_DEBUG_BUILD } from './flags';
var global = getGlobalObject();
var ignoreOnError = 0;
/**
 * @hidden
 */
export function shouldIgnoreOnError() {
  return ignoreOnError > 0;
}
/**
 * @hidden
 */
export function ignoreNextOnError() {
  // onerror should trigger before setTimeout
  ignoreOnError += 1;
  setTimeout(function () {
    ignoreOnError -= 1;
  });
}
/**
 * Instruments the given function and sends an event to Sentry every time the
 * function throws an exception.
 *
 * @param fn A function to wrap.
 * @returns The wrapped function.
 * @hidden
 */
export function wrap(fn, options, before) {
  // for future readers what this does is wrap a function and then create
  // a bi-directional wrapping between them.
  //
  // example: wrapped = wrap(original);
  //  original.__sentry_wrapped__ -> wrapped
  //  wrapped.__sentry_original__ -> original
  if (options === void 0) {
    options = {};
  }
  if (typeof fn !== 'function') {
    return fn;
  }
  try {
    // if we're dealing with a function that was previously wrapped, return
    // the original wrapper.
    var wrapper = fn.__sentry_wrapped__;
    if (wrapper) {
      return wrapper;
    }
    // We don't wanna wrap it twice
    if (getOriginalFunction(fn)) {
      return fn;
    }
  } catch (e) {
    // Just accessing custom props in some Selenium environments
    // can cause a "Permission denied" exception (see raven-js#495).
    // Bail on wrapping and return the function as-is (defers to window.onerror).
    return fn;
  }
  /* eslint-disable prefer-rest-params */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var sentryWrapped = function () {
    var args = Array.prototype.slice.call(arguments);
    try {
      if (before && typeof before === 'function') {
        before.apply(this, arguments);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
      var wrappedArguments = args.map(function (arg) {
        return wrap(arg, options);
      });
      // Attempt to invoke user-land function
      // NOTE: If you are a Sentry user, and you are seeing this stack frame, it
      //       means the sentry.javascript SDK caught an error invoking your application code. This
      //       is expected behavior and NOT indicative of a bug with sentry.javascript.
      return fn.apply(this, wrappedArguments);
    } catch (ex) {
      ignoreNextOnError();
      withScope(function (scope) {
        scope.addEventProcessor(function (event) {
          if (options.mechanism) {
            addExceptionTypeValue(event, undefined, undefined);
            addExceptionMechanism(event, options.mechanism);
          }
          event.extra = __assign(__assign({}, event.extra), {
            arguments: args
          });
          return event;
        });
        captureException(ex);
      });
      throw ex;
    }
  };
  /* eslint-enable prefer-rest-params */
  // Accessing some objects may throw
  // ref: https://github.com/getsentry/sentry-javascript/issues/1168
  try {
    for (var property in fn) {
      if (Object.prototype.hasOwnProperty.call(fn, property)) {
        sentryWrapped[property] = fn[property];
      }
    }
  } catch (_oO) {} // eslint-disable-line no-empty
  // Signal that this function has been wrapped/filled already
  // for both debugging and to prevent it to being wrapped/filled twice
  markFunctionWrapped(sentryWrapped, fn);
  addNonEnumerableProperty(fn, '__sentry_wrapped__', sentryWrapped);
  // Restore original function name (not all browsers allow that)
  try {
    var descriptor = Object.getOwnPropertyDescriptor(sentryWrapped, 'name');
    if (descriptor.configurable) {
      Object.defineProperty(sentryWrapped, 'name', {
        get: function () {
          return fn.name;
        }
      });
    }
    // eslint-disable-next-line no-empty
  } catch (_oO) {}
  return sentryWrapped;
}
/**
 * Injects the Report Dialog script
 * @hidden
 */
export function injectReportDialog(options) {
  if (options === void 0) {
    options = {};
  }
  if (!global.document) {
    return;
  }
  if (!options.eventId) {
    IS_DEBUG_BUILD && logger.error('Missing eventId option in showReportDialog call');
    return;
  }
  if (!options.dsn) {
    IS_DEBUG_BUILD && logger.error('Missing dsn option in showReportDialog call');
    return;
  }
  var script = global.document.createElement('script');
  script.async = true;
  script.src = getReportDialogEndpoint(options.dsn, options);
  if (options.onLoad) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    script.onload = options.onLoad;
  }
  var injectionPoint = global.document.head || global.document.body;
  if (injectionPoint) {
    injectionPoint.appendChild(script);
  }
}
